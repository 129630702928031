.header {
  font-family: var(--fontFamilySansSerif);
  color: var(--color1);
  font-size: 13.5vw;
  letter-spacing: -0.1rem;
  line-height: 0.9;
  margin: 0 auto -0.31em;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
}

.noSearchResultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem auto;
  max-width: 40vw;
  padding-bottom: 2rem;
  color: var(--color1);
}

.searchResultsMessage {
  align-self: center;
  font-family: var(--fontFamilySansSerif);
  font-size: 25vw;
  line-height: 1;
  padding-bottom: 0.5em;
  text-align: center;
  text-transform: capitalize;
}

.searchResultsText {
  align-self: center;

  margin-top: 0rem;
}

@media (min-width: 768px) {
  .resultContainer {
    margin-top: -5rem;
  }
  .searchResultsMessage {
    font-size: 12vw;
  }

  .searchResultsText {
    font-size: 2vw;
  }
}
