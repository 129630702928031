.wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.podcastsContainer {
  margin: 1rem auto 1.5rem auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerLink {
  border: none;
}

.headerLink:hover {
  opacity: 0.75;
}

.podcastsHeader {
  font-family: var(--fontFamilySansSerif);
  color: var(--color1);
  font-size: 14.75vw;
  letter-spacing: -0.1rem;
  line-height: 0.9;
  margin: 0 auto -0.5rem;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
}

.podcastClip {
  width: 15rem;
  margin-bottom: 1rem;
}

.podcastClip:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #fff;
  transition: opacity 0.25s;
  opacity: 0;
}

.podcastClip:hover::after {
  opacity: 0.3;
  transition: opacity 0.25s;
}

/* podcasts */
@media (min-width: 768px) {
  .podcastsContainer {
    margin: -1rem auto 4.5rem auto;
    display: unset;
  }

  .podcastsHeader {
    font-size: 8vw;
    margin: 0 auto -0.75rem;
  }

  .podcastsGrid {
    display: grid;
    justify-content: center;
    gap: 5px;
    grid-template-columns: 35% 35%;
    margin-bottom: 3rem;
  }

  .podcastClip {
    width: unset;
    margin-bottom: unset;
  }
}

@media (min-width: 1025px) {
  .podcastsGrid {
    display: grid;
    grid-template-columns: 30% 30%;
    gap: 25px;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .discoverHeader {
    margin-bottom: -7rem;
    font-size: 13rem;
  }
}

@media (min-width: 1225px) {
  .podcastsGrid {
    grid-template-columns: 20% 20%;
  }
}
