.wrapper {
  display: revert;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  width: 80%;
  max-width: 500px;
}

.iconWrapper {
  position: absolute;
  top: 24%;
  bottom: 0;
  left: 15px;
  margin: auto 0;
}

.clearingX {
  position: absolute;
  right: 5%;
  top: 25%;
  padding: 0;
  border: 0;
  background: transparent;
  color: #fff;
  font-size: 14px;
}

.hiddenClearingX {
  display: none;
}

.dippSearchInput {
  box-sizing: border-box;
  padding: 0.75rem 3rem;
  background: white;
  border: 2px solid var(--colorFG);
  width: 100%;
  color: var(--colorFG);
}

.dippSearchInput:hover {
  box-sizing: border-box;
  border: 1px solid var(--color3);
}

.dippSearchInput:focus {
  box-sizing: border-box;
  border: 1px solid var(--color3);
}

.dippSearchInput::placeholder {
  opacity: 0.6;
}

.dippSearchInput:focus::placeholder {
  opacity: 0.3;
}

.dippSearchInput::-webkit-search-cancel-button {
  display: none;
}

@media (min-width: 768px) {
  .wrapper {
    margin-top: 3rem;
    margin-bottom: 5rem;
    width: 40%;
    max-width: 500px;
  }

  .iconWrapper {
    position: absolute;
    top: 12px;
    bottom: 0;
    right: 30px;
    left: revert;
    margin: auto 0;
  }

  .iconWrapperHidden {
    display: none;
  }

  .dippSearchInput {
    box-sizing: border-box;
    padding-left: 2rem;
    padding-right: 2rem;
    background: transparent;
    width: 100%;
  }

  .dippSearchInput:hover {
    box-sizing: border-box;
    border: 2px solid var(--color3);
  }

  .dippSearchInput:focus {
    box-sizing: border-box;
    border: 2px solid var(--color3);
  }

  .dippSearchInput::placeholder {
    opacity: 0.6;
    color: var(--colorFG);
    font-family: var(--fontFamilySansSerif);
    font-size: 18px;
  }

  .dippSearchInput:focus::placeholder {
    opacity: 0.3;
  }
}
