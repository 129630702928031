.wrapper {
  width: 95%;
  margin: 0 auto;
  box-shadow: var(--subtleBoxShadow);
  margin-top: 0.75rem;
  margin-bottom: 3rem;
}

.communityLink {
  border: none;
}

.header {
  background: var(--colorBGA);
  width: 100%;
  padding: 0.5rem;
  padding-left: 2rem;
  font-size: 1.1rem;
  font-family: var(--fontFamilyHeading);
}

.communityContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  padding-bottom: 0.5rem;
}

.communityWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hiddenOnMobile {
  display: none;
}

.communityImage {
  width: fit-content;
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.featureImage img {
  border-radius: 50%;
}

.communityName {
  font-size: 1rem;
  font-family: var(--fontFamilyHeading);
}

/* -- follow button -- */

.pillWrapper {
  margin: 0.5rem;
  margin-left: 1rem;
}

.pill {
  padding: 0.2rem 1rem;
  font-size: 0.75rem;
  color: var(--color1);
  border-color: var(--color1);
  opacity: 0.8;
}

.pill:hover {
  opacity: 0.4;
}

.active {
  color: #fff;
  background-color: var(--color1);
}

@media (min-width: 768px) {
  .wrapper {
    width: var(--maxBodyWidth);
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  .communityContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem 2rem;
  }

  .hiddenOnMobile {
    display: flex;
  }
}
